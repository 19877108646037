
          export const batfishContext = {
            selectedConfig: {
              siteBasePath: '',
              siteOrigin: 'https://docs.goong.io',
              hijackLinks: true,
              manageScrollRestoration: true
            },
            routes: [{
            path: '/',
            getPage: () => import(
              /* webpackChunkName: "home" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_.js'
            ),
            
            
          },{
            path: '/example/',
            getPage: () => import(
              /* webpackChunkName: "example" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example.js'
            ),
            
            
          },{
            path: '/example/3d-buildings/',
            getPage: () => import(
              /* webpackChunkName: "example-3d-buildings" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_3d-buildings.js'
            ),
            
            
          },{
            path: '/example/3d-extrusion-floorplan/',
            getPage: () => import(
              /* webpackChunkName: "example-3d-extrusion-floorplan" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_3d-extrusion-floorplan.js'
            ),
            
            
          },{
            path: '/example/add-3d-model/',
            getPage: () => import(
              /* webpackChunkName: "example-add-3d-model" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-3d-model.js'
            ),
            
            
          },{
            path: '/example/add-a-geocoder/',
            getPage: () => import(
              /* webpackChunkName: "example-add-a-geocoder" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-a-geocoder.js'
            ),
            
            
          },{
            path: '/example/add-a-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-add-a-marker" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-a-marker.js'
            ),
            
            
          },{
            path: '/example/add-image-animated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-animated" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-image-animated.js'
            ),
            
            
          },{
            path: '/example/add-image-generated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-generated" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-image-generated.js'
            ),
            
            
          },{
            path: '/example/add-image-missing-generated/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-missing-generated" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-image-missing-generated.js'
            ),
            
            
          },{
            path: '/example/add-image-stretchable/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image-stretchable" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-image-stretchable.js'
            ),
            
            
          },{
            path: '/example/add-image/',
            getPage: () => import(
              /* webpackChunkName: "example-add-image" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_add-image.js'
            ),
            
            
          },{
            path: '/example/animate-a-line/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-a-line" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-a-line.js'
            ),
            
            
          },{
            path: '/example/animate-camera-around-point/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-camera-around-point" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-camera-around-point.js'
            ),
            
            
          },{
            path: '/example/animate-images/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-images" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-images.js'
            ),
            
            
          },{
            path: '/example/animate-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-marker" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-marker.js'
            ),
            
            
          },{
            path: '/example/animate-point-along-line/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-point-along-line" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-point-along-line.js'
            ),
            
            
          },{
            path: '/example/animate-point-along-route/',
            getPage: () => import(
              /* webpackChunkName: "example-animate-point-along-route" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_animate-point-along-route.js'
            ),
            
            
          },{
            path: '/example/attribution-position/',
            getPage: () => import(
              /* webpackChunkName: "example-attribution-position" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_attribution-position.js'
            ),
            
            
          },{
            path: '/example/camera-animation/',
            getPage: () => import(
              /* webpackChunkName: "example-camera-animation" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_camera-animation.js'
            ),
            
            
          },{
            path: '/example/canvas-source/',
            getPage: () => import(
              /* webpackChunkName: "example-canvas-source" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_canvas-source.js'
            ),
            
            
          },{
            path: '/example/center-on-symbol/',
            getPage: () => import(
              /* webpackChunkName: "example-center-on-symbol" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_center-on-symbol.js'
            ),
            
            
          },{
            path: '/example/change-building-color-based-on-zoom-level/',
            getPage: () => import(
              /* webpackChunkName: "example-change-building-color-based-on-zoom-level" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_change-building-color-based-on-zoom-level.js'
            ),
            
            
          },{
            path: '/example/change-case-of-labels/',
            getPage: () => import(
              /* webpackChunkName: "example-change-case-of-labels" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_change-case-of-labels.js'
            ),
            
            
          },{
            path: '/example/check-for-support/',
            getPage: () => import(
              /* webpackChunkName: "example-check-for-support" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_check-for-support.js'
            ),
            
            
          },{
            path: '/example/cluster-html/',
            getPage: () => import(
              /* webpackChunkName: "example-cluster-html" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_cluster-html.js'
            ),
            
            
          },{
            path: '/example/cluster/',
            getPage: () => import(
              /* webpackChunkName: "example-cluster" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_cluster.js'
            ),
            
            
          },{
            path: '/example/color-switcher/',
            getPage: () => import(
              /* webpackChunkName: "example-color-switcher" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_color-switcher.js'
            ),
            
            
          },{
            path: '/example/custom-marker-icons/',
            getPage: () => import(
              /* webpackChunkName: "example-custom-marker-icons" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_custom-marker-icons.js'
            ),
            
            
          },{
            path: '/example/custom-style-layer/',
            getPage: () => import(
              /* webpackChunkName: "example-custom-style-layer" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_custom-style-layer.js'
            ),
            
            
          },{
            path: '/example/data-driven-lines/',
            getPage: () => import(
              /* webpackChunkName: "example-data-driven-lines" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_data-driven-lines.js'
            ),
            
            
          },{
            path: '/example/disable-rotation/',
            getPage: () => import(
              /* webpackChunkName: "example-disable-rotation" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_disable-rotation.js'
            ),
            
            
          },{
            path: '/example/disable-scroll-zoom/',
            getPage: () => import(
              /* webpackChunkName: "example-disable-scroll-zoom" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_disable-scroll-zoom.js'
            ),
            
            
          },{
            path: '/example/display-and-style-rich-text-labels/',
            getPage: () => import(
              /* webpackChunkName: "example-display-and-style-rich-text-labels" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_display-and-style-rich-text-labels.js'
            ),
            
            
          },{
            path: '/example/drag-a-marker/',
            getPage: () => import(
              /* webpackChunkName: "example-drag-a-marker" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_drag-a-marker.js'
            ),
            
            
          },{
            path: '/example/drag-a-point/',
            getPage: () => import(
              /* webpackChunkName: "example-drag-a-point" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_drag-a-point.js'
            ),
            
            
          },{
            path: '/example/draw-route-line/',
            getPage: () => import(
              /* webpackChunkName: "example-draw-route-line" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_draw-route-line.js'
            ),
            
            
          },{
            path: '/example/fallback-image/',
            getPage: () => import(
              /* webpackChunkName: "example-fallback-image" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_fallback-image.js'
            ),
            
            
          },{
            path: '/example/featuresat/',
            getPage: () => import(
              /* webpackChunkName: "example-featuresat" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_featuresat.js'
            ),
            
            
          },{
            path: '/example/fill-pattern/',
            getPage: () => import(
              /* webpackChunkName: "example-fill-pattern" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_fill-pattern.js'
            ),
            
            
          },{
            path: '/example/filter-markers-by-input/',
            getPage: () => import(
              /* webpackChunkName: "example-filter-markers-by-input" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_filter-markers-by-input.js'
            ),
            
            
          },{
            path: '/example/filter-markers/',
            getPage: () => import(
              /* webpackChunkName: "example-filter-markers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_filter-markers.js'
            ),
            
            
          },{
            path: '/example/fitbounds/',
            getPage: () => import(
              /* webpackChunkName: "example-fitbounds" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_fitbounds.js'
            ),
            
            
          },{
            path: '/example/flyto-options/',
            getPage: () => import(
              /* webpackChunkName: "example-flyto-options" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_flyto-options.js'
            ),
            
            
          },{
            path: '/example/flyto/',
            getPage: () => import(
              /* webpackChunkName: "example-flyto" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_flyto.js'
            ),
            
            
          },{
            path: '/example/fullscreen/',
            getPage: () => import(
              /* webpackChunkName: "example-fullscreen" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_fullscreen.js'
            ),
            
            
          },{
            path: '/example/game-controls/',
            getPage: () => import(
              /* webpackChunkName: "example-game-controls" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_game-controls.js'
            ),
            
            
          },{
            path: '/example/geojson-layer-in-stack/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-layer-in-stack" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_geojson-layer-in-stack.js'
            ),
            
            
          },{
            path: '/example/geojson-line/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-line" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_geojson-line.js'
            ),
            
            
          },{
            path: '/example/geojson-markers/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-markers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_geojson-markers.js'
            ),
            
            
          },{
            path: '/example/geojson-polygon/',
            getPage: () => import(
              /* webpackChunkName: "example-geojson-polygon" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_geojson-polygon.js'
            ),
            
            
          },{
            path: '/example/goong-geocoder-without-map/',
            getPage: () => import(
              /* webpackChunkName: "example-goong-geocoder-without-map" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_goong-geocoder-without-map.js'
            ),
            
            
          },{
            path: '/example/heatmap-layer/',
            getPage: () => import(
              /* webpackChunkName: "example-heatmap-layer" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_heatmap-layer.js'
            ),
            
            
          },{
            path: '/example/heatmap/',
            getPage: () => import(
              /* webpackChunkName: "example-heatmap" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_heatmap.js'
            ),
            
            
          },{
            path: '/example/hover-styles/',
            getPage: () => import(
              /* webpackChunkName: "example-hover-styles" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_hover-styles.js'
            ),
            
            
          },{
            path: '/example/interactive-false/',
            getPage: () => import(
              /* webpackChunkName: "example-interactive-false" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_interactive-false.js'
            ),
            
            
          },{
            path: '/example/jump-to/',
            getPage: () => import(
              /* webpackChunkName: "example-jump-to" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_jump-to.js'
            ),
            
            
          },{
            path: '/example/language-switch/',
            getPage: () => import(
              /* webpackChunkName: "example-language-switch" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_language-switch.js'
            ),
            
            
          },{
            path: '/example/line-across-180th-meridian/',
            getPage: () => import(
              /* webpackChunkName: "example-line-across-180th-meridian" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_line-across-180th-meridian.js'
            ),
            
            
          },{
            path: '/example/line-gradient/',
            getPage: () => import(
              /* webpackChunkName: "example-line-gradient" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_line-gradient.js'
            ),
            
            
          },{
            path: '/example/live-geojson/',
            getPage: () => import(
              /* webpackChunkName: "example-live-geojson" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_live-geojson.js'
            ),
            
            
          },{
            path: '/example/live-update-feature/',
            getPage: () => import(
              /* webpackChunkName: "example-live-update-feature" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_live-update-feature.js'
            ),
            
            
          },{
            path: '/example/locate-user/',
            getPage: () => import(
              /* webpackChunkName: "example-locate-user" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_locate-user.js'
            ),
            
            
          },{
            path: '/example/map-tiles/',
            getPage: () => import(
              /* webpackChunkName: "example-map-tiles" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_map-tiles.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-draw/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-draw" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_mapbox-gl-draw.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-rtl-text/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-rtl-text" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_mapbox-gl-rtl-text.js'
            ),
            
            
          },{
            path: '/example/mapbox-gl-supported/',
            getPage: () => import(
              /* webpackChunkName: "example-mapbox-gl-supported" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_mapbox-gl-supported.js'
            ),
            
            
          },{
            path: '/example/marker-popup/',
            getPage: () => import(
              /* webpackChunkName: "example-marker-popup" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_marker-popup.js'
            ),
            
            
          },{
            path: '/example/measure/',
            getPage: () => import(
              /* webpackChunkName: "example-measure" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_measure.js'
            ),
            
            
          },{
            path: '/example/mouse-position/',
            getPage: () => import(
              /* webpackChunkName: "example-mouse-position" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_mouse-position.js'
            ),
            
            
          },{
            path: '/example/multiple-geometries/',
            getPage: () => import(
              /* webpackChunkName: "example-multiple-geometries" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_multiple-geometries.js'
            ),
            
            
          },{
            path: '/example/navigation/',
            getPage: () => import(
              /* webpackChunkName: "example-navigation" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_navigation.js'
            ),
            
            
          },{
            path: '/example/offset-vanishing-point-with-padding/',
            getPage: () => import(
              /* webpackChunkName: "example-offset-vanishing-point-with-padding" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_offset-vanishing-point-with-padding.js'
            ),
            
            
          },{
            path: '/example/polygon-popup-on-click/',
            getPage: () => import(
              /* webpackChunkName: "example-polygon-popup-on-click" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_polygon-popup-on-click.js'
            ),
            
            
          },{
            path: '/example/popup-on-click/',
            getPage: () => import(
              /* webpackChunkName: "example-popup-on-click" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_popup-on-click.js'
            ),
            
            
          },{
            path: '/example/popup-on-hover/',
            getPage: () => import(
              /* webpackChunkName: "example-popup-on-hover" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_popup-on-hover.js'
            ),
            
            
          },{
            path: '/example/popup/',
            getPage: () => import(
              /* webpackChunkName: "example-popup" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_popup.js'
            ),
            
            
          },{
            path: '/example/queryrenderedfeatures/',
            getPage: () => import(
              /* webpackChunkName: "example-queryrenderedfeatures" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_queryrenderedfeatures.js'
            ),
            
            
          },{
            path: '/example/render-world-copies/',
            getPage: () => import(
              /* webpackChunkName: "example-render-world-copies" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_render-world-copies.js'
            ),
            
            
          },{
            path: '/example/restrict-bounds/',
            getPage: () => import(
              /* webpackChunkName: "example-restrict-bounds" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_restrict-bounds.js'
            ),
            
            
          },{
            path: '/example/satellite-map/',
            getPage: () => import(
              /* webpackChunkName: "example-satellite-map" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_satellite-map.js'
            ),
            
            
          },{
            path: '/example/scroll-fly-to/',
            getPage: () => import(
              /* webpackChunkName: "example-scroll-fly-to" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_scroll-fly-to.js'
            ),
            
            
          },{
            path: '/example/set-perspective/',
            getPage: () => import(
              /* webpackChunkName: "example-set-perspective" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_set-perspective.js'
            ),
            
            
          },{
            path: '/example/set-popup/',
            getPage: () => import(
              /* webpackChunkName: "example-set-popup" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_set-popup.js'
            ),
            
            
          },{
            path: '/example/setstyle/',
            getPage: () => import(
              /* webpackChunkName: "example-setstyle" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_setstyle.js'
            ),
            
            
          },{
            path: '/example/simple-map/',
            getPage: () => import(
              /* webpackChunkName: "example-simple-map" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_simple-map.js'
            ),
            
            
          },{
            path: '/example/third-party/',
            getPage: () => import(
              /* webpackChunkName: "example-third-party" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_third-party.js'
            ),
            
            
          },{
            path: '/example/timeline-animation/',
            getPage: () => import(
              /* webpackChunkName: "example-timeline-animation" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_timeline-animation.js'
            ),
            
            
          },{
            path: '/example/toggle-interaction-handlers/',
            getPage: () => import(
              /* webpackChunkName: "example-toggle-interaction-handlers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_toggle-interaction-handlers.js'
            ),
            
            
          },{
            path: '/example/using-featuresin/',
            getPage: () => import(
              /* webpackChunkName: "example-using-featuresin" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_using-featuresin.js'
            ),
            
            
          },{
            path: '/example/variable-label-placement/',
            getPage: () => import(
              /* webpackChunkName: "example-variable-label-placement" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_variable-label-placement.js'
            ),
            
            
          },{
            path: '/example/vector-source/',
            getPage: () => import(
              /* webpackChunkName: "example-vector-source" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_vector-source.js'
            ),
            
            
          },{
            path: '/example/video-on-a-map/',
            getPage: () => import(
              /* webpackChunkName: "example-video-on-a-map" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_video-on-a-map.js'
            ),
            
            
          },{
            path: '/example/visualize-population-density/',
            getPage: () => import(
              /* webpackChunkName: "example-visualize-population-density" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_visualize-population-density.js'
            ),
            
            
          },{
            path: '/example/wms/',
            getPage: () => import(
              /* webpackChunkName: "example-wms" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_wms.js'
            ),
            
            
          },{
            path: '/example/zoomto-linestring/',
            getPage: () => import(
              /* webpackChunkName: "example-zoomto-linestring" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_example_zoomto-linestring.js'
            ),
            
            
          },{
            path: '/examples/',
            getPage: () => import(
              /* webpackChunkName: "examples" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_examples.js'
            ),
            
            
          },{
            path: '/help/',
            getPage: () => import(
              /* webpackChunkName: "help" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_help.js'
            ),
            
            
          },{
            path: '/javascript/',
            getPage: () => import(
              /* webpackChunkName: "javascript" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript.js'
            ),
            
            
          },{
            path: '/javascript/events/',
            getPage: () => import(
              /* webpackChunkName: "javascript-events" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_events.js'
            ),
            
            
          },{
            path: '/javascript/geography/',
            getPage: () => import(
              /* webpackChunkName: "javascript-geography" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_geography.js'
            ),
            
            
          },{
            path: '/javascript/handlers/',
            getPage: () => import(
              /* webpackChunkName: "javascript-handlers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_handlers.js'
            ),
            
            
          },{
            path: '/javascript/map/',
            getPage: () => import(
              /* webpackChunkName: "javascript-map" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_map.js'
            ),
            
            
          },{
            path: '/javascript/markers/',
            getPage: () => import(
              /* webpackChunkName: "javascript-markers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_markers.js'
            ),
            
            
          },{
            path: '/javascript/properties/',
            getPage: () => import(
              /* webpackChunkName: "javascript-properties" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_properties.js'
            ),
            
            
          },{
            path: '/javascript/sources/',
            getPage: () => import(
              /* webpackChunkName: "javascript-sources" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_javascript_sources.js'
            ),
            
            
          },{
            path: '/mobiles/',
            getPage: () => import(
              /* webpackChunkName: "mobiles" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_mobiles.js'
            ),
            
            
          },{
            path: '/overview/',
            getPage: () => import(
              /* webpackChunkName: "overview" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_overview.js'
            ),
            
            
          },{
            path: '/plugins/',
            getPage: () => import(
              /* webpackChunkName: "plugins" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_plugins.js'
            ),
            
            
          },{
            path: '/rest/',
            getPage: () => import(
              /* webpackChunkName: "rest" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest.js'
            ),
            
            
          },{
            path: '/rest/api-key/',
            getPage: () => import(
              /* webpackChunkName: "rest-api-key" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_api-key.js'
            ),
            
            
          },{
            path: '/rest/directions/',
            getPage: () => import(
              /* webpackChunkName: "rest-directions" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_directions.js'
            ),
            
            
          },{
            path: '/rest/distance_matrix/',
            getPage: () => import(
              /* webpackChunkName: "rest-distance-matrix" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_distance_matrix.js'
            ),
            
            
          },{
            path: '/rest/geocode/',
            getPage: () => import(
              /* webpackChunkName: "rest-geocode" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_geocode.js'
            ),
            
            
          },{
            path: '/rest/place/',
            getPage: () => import(
              /* webpackChunkName: "rest-place" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_place.js'
            ),
            
            
          },{
            path: '/rest/speed-limit/',
            getPage: () => import(
              /* webpackChunkName: "rest-speed-limit" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_speed-limit.js'
            ),
            
            
          },{
            path: '/rest/staticmap/',
            getPage: () => import(
              /* webpackChunkName: "rest-staticmap" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_staticmap.js'
            ),
            
            
          },{
            path: '/rest/trip/',
            getPage: () => import(
              /* webpackChunkName: "rest-trip" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_rest_trip.js'
            ),
            
            
          },{
            path: '/style-spec/',
            getPage: () => import(
              /* webpackChunkName: "style-spec" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec.js'
            ),
            
            
          },{
            path: '/style-spec/expressions/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-expressions" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_expressions.js'
            ),
            
            
          },{
            path: '/style-spec/glyphs/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-glyphs" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_glyphs.js'
            ),
            
            
          },{
            path: '/style-spec/layers/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-layers" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_layers.js'
            ),
            
            
          },{
            path: '/style-spec/light/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-light" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_light.js'
            ),
            
            
          },{
            path: '/style-spec/other/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-other" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_other.js'
            ),
            
            
          },{
            path: '/style-spec/root/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-root" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_root.js'
            ),
            
            
          },{
            path: '/style-spec/sources/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-sources" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_sources.js'
            ),
            
            
          },{
            path: '/style-spec/sprite/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-sprite" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_sprite.js'
            ),
            
            
          },{
            path: '/style-spec/transition/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-transition" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_transition.js'
            ),
            
            
          },{
            path: '/style-spec/types/',
            getPage: () => import(
              /* webpackChunkName: "style-spec-types" */
              '/home/runner/work/goong-io.github.io/goong-io.github.io/_batfish_tmp/_style-spec_types.js'
            ),
            
            
          }],
            notFoundRoute: {
      path: '',
      getPage: () => { throw new Error('No matching route.'); },
      is404: true
    }
          };